































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getBridgeEvents } from '@/api/properties'
import Pagination from '@/components/Pagination/index.vue'
import {
  createFilter,
  FilterOperator,
  FilterType, getSortItems,
  prepareListFilters
} from '@/utils/filter'
import { parseTime } from '@/utils'

@Component({
  name: 'BridgeEvents',
  components: {
    Pagination
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  private total = 0
  private list = []
  private listLoading = false
  private parseTime = parseTime
  private listQuery = createFilter()

  created() {
    this.getList()
  }

  private getStatus(status: any) {
    // switch (status) {
    //   case 0: {
    //     return this.$t('services.spitogatos')
    //   }
    //   case 1: {
    //     return this.$t('services.xe')
    //   }
    //   case 2: {
    //     return this.$t('services.plot')
    //   }
    //   default: {
    //     return ''
    //   }
    // }
    return ''
  }

  private getType(type: any) {
    switch (type) {
      case 0: {
        return this.$t('services.spitogatos')
      }
      case 1: {
        return this.$t('services.xe')
      }
      case 2: {
        return this.$t('services.plot')
      }
      default: {
        return ''
      }
    }
  }

  private getMessage(data: any) {
    if (data.error) {
      return data.error
    }
    if (data.action) {
      switch (data.action) {
        case 'delete': {
          return this.$t('propertyDetail.service_delete')
        }
        case 'save': {
          return this.$t('propertyDetail.service_save')
        }
        default: {
          return this.$t('propertyDetail.successful_entry')
        }
      }
    }
    return ''
  }

  private tableRowClassName(data: any) {
    const { row } = data
    if (row.meta.error) {
      return 'warning-row'
    }
    return 'success-row'
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: '',
      searchFields: [],
      filters: [],
      callback: () => {
        this.listQuery.query?.filters?.items?.push({
          type: FilterType.field,
          key: 'property_id',
          value: this.property.id,
          operator: FilterOperator.eq
        })
      }
    })
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getBridgeEvents(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"bee-row-container flex-wrap",attrs:{"gutter":8,"type":"flex"}},[_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":"","row-class-name":_vm.tableRowClassName},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getType(row.type)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getStatus(row.status)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.message')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getMessage(row.meta)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created)))])]}}])})],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }